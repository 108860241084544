<template>

    <UForm id="signinform" :schema="schema" :state="state" @submit="handleSubmit"
    class="flex flex-col size-full gap-2 min-w-28 max-w-[19.5rem] mx-auto select-none">

        <h4 class=" text-2xl font-bold text-gray-600 mb-3">Content de vous revoir !</h4>

        <!-- fake button for autofocus -->
        <button class="" type="submit"></button>

        <UFormField required label="E-mail" name="email" >
            <UInput :autofocus="false" variant="outline" size="xl" placeholder="exemple@gmail.com" v-model="state.email"
                autocomplete="email"
                class="w-full"></UInput>
        </UFormField>

        <UFormField required label="Mot de passe" name="password" class="mt-2">
            <UInput :autofocus="false" size="xl" :placeholder="showPassword ? '' : '••••••••'"
                autocomplete="current-password" v-model="state.password" :type="showPassword ? 'text' : 'password'"
                class="w-full"
                >
                <template #trailing>
                    <UButton variant="link" color="neutral" @click="() => { showPassword = !showPassword }">
                        <UIcon :name="showPassword ? 'ph-eye-slash' : 'ph-eye'" size="22"></UIcon>
                    </UButton>

                </template>
            </UInput>
        </UFormField>



        <UFormField size="xl" class="mt-6" :error="errorMessage">
            <UButton loading-icon="ph-spinner" :loading="passwordLoading" size="xl" block type="submit"
                form="signinform" color="primary">
                Se connecter
            </UButton>
        </UFormField>

        <div class="flex justify-center items-center relative  mt-3">
            <div class="flex-1 h-px bg-gray-400"></div>
            <span class="flex items-center px-2.5 py-0.5 text-xl font-extrabold">ou</span>
            <div class="flex-1 h-px bg-gray-400 "></div>
        </div>

        <button @click.prevent="webautnAuthenticate()"
            class="py-2  group relative min-h-20 flex flex-col items-center justify-center gap-2 active:bg-lolo-100 transition-colors rounded-full">
            <UIcon v-if="!webauthnLoading" size="50"
                class=" group-hover:text-lolo-400 group-active:text-lolo-500 text-lolo-300" name="ph:fingerprint">
            </UIcon>
            <UIcon v-else size="50" class=" text-lolo-400 animate-spin" name="ph:spinner">
            </UIcon>
            <p class="text-sm font-semibold text-gray-500 group-active:text-gray-700 group-hover:text-gray-600">
                Selectionner une clé sécurisée</p>
        </button>

            <div class="flex-1"></div>
            <div class="text-sm text-lolo-500  underline-offset-4 flex items-center justify-center gap-4 ">
                <NuxtLinkLocale v-slot="{ href, navigate }" replace custom to="/sign-up">
                    <a :href="href" @click.prevent="$emit('redirect', href)"
                        class="hover:underline  hover:text-lolo-600 active:text-lolo-800">
                        Créer un compte</a>
                </NuxtLinkLocale>
                <span class="text-gray-600 ">/</span>
                <NuxtLinkLocale v-slot="{ href, navigate }" replace custom to="/forgot-password">
                    <a :href="href" @click.prevent="$emit('redirect', href)"
                        class="hover:underline  hover:text-lolo-600 active:text-lolo-800">
                        Mot de passe oublié</a>
                </NuxtLinkLocale>
            </div>
        
    </UForm>






</template>

<script lang="ts" setup>
    import type { FormErrorEvent, FormSubmitEvent } from '#ui/types'
    import { object, string, type InferType } from 'yup';

    const { authenticate } = useMyWebauthn()
    const showPassword = ref(false)
    const passwordLoading = ref(false)
    const webauthnLoading = ref(false)
    const errorMessage = ref('')

    const emit = defineEmits<{ success: [], redirect: [fullpath: string], requestLogin: [], requestEchec: [] }>()

    const schema = object({
        email: string()
            .email('Email invalide')
            .required('Email requis'),
        password: string()
            .matches(...emailMatch)
            .required('Le mot de passe est requis')
    })
    type Schema = InferType<typeof schema>

    const state = reactive<Schema>({
        email: '',
        password: '',
    })

    const handleSubmit = async (event: FormSubmitEvent<Schema>) => {
        emit('requestLogin')
        passwordLoading.value = true
        console.log(event.data)

        await $fetch('/account/sign-in', { body: event.data, method: 'POST' })
            .then(async () => await success())
            .catch((e) => {
                errorMessage.value = e.data.message
                emit('requestEchec')
            })
            .finally(() => {
                passwordLoading.value = false
            })
    }


    const webautnAuthenticate = async () => {
        emit('requestLogin')
        webauthnLoading.value = true
        await authenticate('default')
            .then(async () => await success())
            .catch((e) => {
                emit('requestEchec')
                console.log('hello', e.code)
            }).finally(() => {
                webauthnLoading.value = false
            })
    }

    const success = async () => {
        const { fetch } = useUserSession()
        await fetch()
        emit('success')
    }
</script>

<style></style>